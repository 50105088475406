import { createValidator, required } from '@black-bear-energy/react-common';

const formValidator = createValidator({
  name: {
    label: 'Name',
    validators: [required],
  },
  email: {
    label: 'Email',
    validators: [required],
  },
  companyName: {
    label: 'Company Name',
    validators: [required],
  },
  siteAddress: {
    label: 'Site Address',
    validators: [required],
  },
});

export default formValidator;
