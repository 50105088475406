import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Col, Container, Nav, Row, Image } from 'react-bootstrap';
import { TEXT } from '../components/common/BlackBearEnergy';
import { IStyledComponentPropTypes } from '../common/types';

interface IHeaderPropTypes extends IStyledComponentPropTypes {
  clientName: string;
  entity: Record<string, any>;
}

const LogoImage = styled(Image)`
  height: 30px;
`;

function Header({ clientName, entity, className }: IHeaderPropTypes) {
  return (
    <Container as="nav" fluid className={className}>
      <Row>
        <Col xs={6} md={8}>
          <Nav as="ul">
            <Nav.Item as="li">
              <NavLink to={`/${clientName}`}>
                <LogoImage src={entity.logoUrl} />
              </NavLink>
            </Nav.Item>
          </Nav>
        </Col>
        <Col xs={6} md={4}>
          <Nav as="ul">
            <Nav.Item as="li">
              <a href="#our-energy-program">Our Energy Program</a>
            </Nav.Item>
            <Nav.Item as="li">
              <a href="#faq">FAQ</a>
            </Nav.Item>
            <Nav.Item as="li">
              <a href="#participate">Participate</a>
            </Nav.Item>
          </Nav>
        </Col>
      </Row>
    </Container>
  );
}

export default styled(Header)`
  position: fixed;
  z-index: 9999999999999;
  background-color: #fff;
  top: 0;
  left: 0;
  padding-left: 30px;
  padding-right: 30px;

  .nav {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .nav-item {
    margin-right: 0px;
    margin-left: auto;
    padding-top: 11px;
    padding-bottom: 15px;
  }
  .nav-item:first-child {
    margin-left: 0px;
  }
  a {
    color: ${TEXT};
    font-weight: bold;
  }
  a:hover {
    text-decoration: underline !important;
  }
`;
