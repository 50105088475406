import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';

function NotFound() {
  return (
    <Container fluid>
      <Row>
        <Col lg={{ span: 8, offset: 2 }} sm={{ span: 10, offset: 1 }} xs={12}>
          <Card>
            <Card.Header>Tenant not found</Card.Header>
            <Card.Body>
              There was an error in your request. No tenant was found.
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default NotFound;
