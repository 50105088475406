import React from 'react';
import styled from 'styled-components';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import get from 'just-safe-get';
import formValidator from '../../utils/formValidation';
import { SectionLabel } from './Common';

interface IParticipateProps {
  initialValues: Record<string, any>;
  // NOTE - We should probably use a better type here.
  onSubmit: Function;
}

const ParticipateContainer = styled.div`
  padding-top: 40px;
  padding-bottom: 400px;
`;

function Participate({ initialValues, onSubmit }: IParticipateProps) {
  return (
    <ParticipateContainer>
      <Row>
        <Col>
          <SectionLabel>Participate</SectionLabel>
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 6, offset: 3 }}>
          <Formik
            /* @ts-ignore */
            onSubmit={onSubmit}
            initialValues={initialValues}
            validate={formValidator}
          >
            {({
              values,
              handleSubmit,
              handleChange,
              handleBlur,
              errors,
              touched,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Name
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control
                      name="name"
                      defaultValue={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="text"
                    />
                    {get(touched, 'name') && get(errors, 'name')}
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Email
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control
                      type="email"
                      defaultValue={values.email}
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {get(touched, 'email') && get(errors, 'email')}
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Company Name
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control
                      defaultValue={values.companyName}
                      name="companyName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="text"
                    />
                    {get(touched, 'companyName') && get(errors, 'companyName')}
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Address
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control
                      defaultValue={values.siteAddress}
                      name="siteAddress"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="text"
                    />
                    {get(touched, 'siteAddress') && get(errors, 'siteAddress')}
                  </Col>
                </Form.Group>
                <Button
                  type="submit"
                  disabled={
                    Object.keys(errors).length !== 0 &&
                    errors.constructor === Object
                  }
                >
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </ParticipateContainer>
  );
}

export default Participate;
