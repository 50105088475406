import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './App.css';
import NotFound from './containers/NotFound';
import TenantUtilityForm from './containers/TenantUtilityForm';
import Success from './containers/Success';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/:clientName" element={<TenantUtilityForm />} />
        <Route path="/success" element={<Success />} />
        <Route path="/not-found" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
