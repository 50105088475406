import { createStore } from '@black-bear-energy/react-common';

const { store, modules, StateProvider } = createStore({
  modules: {
    // @ts-ignore
    entities: {
      url: '/entities',
    },
    // @ts-ignore
    tenantDataRequests: {
      url: '/tenant-data-requests',
    },
    // @ts-ignore
    faq: {
      url: '/faq',
    },
    // @ts-ignore
    confirmation: {
      url: '/confirmation',
    },
    // @ts-ignore
    intro: {
      url: '/intro',
    },
  },
  options: {
    baseURL: '/api/v1',
  },
});

// @ts-ignore
const { confirmation, entities, faq, tenantDataRequests, intro } = modules;

export {
  intro,
  store,
  StateProvider,
  confirmation,
  entities,
  faq,
  tenantDataRequests,
};
