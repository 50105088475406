import { Button, Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { confirmation as confirmationModule } from '../state/store';

const { useGetAll: useGetRedirectURL } = confirmationModule;

function ConfirmationModal() {
  const [show, setShow] = useState(true);
  const { method: getURL, data: url } = useGetRedirectURL();
  const handleClose = () => {
    window.location.href = url.url;
    setShow(true);
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getURL();
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <>
      <Modal show={show}>
        <Modal.Header closeButton>
          <Modal.Title>Your information has been submitted.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Your data has been successfully submitted. When you click "Ok" you
          will be directed to the Utility API form, which allows data to come
          directly from your utility.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ConfirmationModal;
