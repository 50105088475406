import React from 'react';
import { Table } from 'react-bootstrap';
import styled from 'styled-components';

const FAQTableContainer = styled.div`
  margin: 10px 20px 40px;
`;

function FAQTable() {
  return (
    <FAQTableContainer>
      <Table bordered>
        <thead>
          <tr>
            <th></th>
            <th>Before Solar: Status Quo</th>
            <th>After Solar: SaaA</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Total Electricity Demand (kWh/yr)</td>
            <td>500,000</td>
            <td>500,000</td>
          </tr>
          <tr>
            <td>Utility Generation (kWh/yr)</td>
            <td>500,000</td>
            <td>75,000</td>
          </tr>
          <tr>
            <td>Utility Expense ($/kWh)</td>
            <td>0.18</td>
            <td>0.18</td>
          </tr>
          <tr>
            <td>Utility Expense ($/yr)</td>
            <td>90,000</td>
            <td>22,000</td>
          </tr>
          <tr>
            <td>Solar Generation (kWh/yr)</td>
            <td>-</td>
            <td>425,000</td>
          </tr>
          <tr>
            <td>Solar Expense ($/kWh)</td>
            <td>-</td>
            <td>0.144</td>
          </tr>
          <tr>
            <td>Solar Expense aka Energy Rent ($/yr)</td>
            <td>-</td>
            <td>61,200</td>
          </tr>
          <tr>
            <td>Total Energy Spend ($/yr)</td>
            <td>90,000</td>
            <td>83,200</td>
          </tr>
          <tr>
            <td>Total Emissions Reductions (t CO2/yr)</td>
            <td>-</td>
            <td>340</td>
          </tr>
          <tr>
            <td>% of Energy from RE</td>
            <td>20%</td>
            <td>88%</td>
          </tr>
        </tbody>
      </Table>
    </FAQTableContainer>
  );
}

export default FAQTable;
