import React from 'react';
import { Row } from 'react-bootstrap';
import styled from 'styled-components';
import { LIGHT_GRAY, TEXT } from '../../components/common/BlackBearEnergy';

const SectionLabelContainer = styled.label`
  width: 100%;
  text-align: center;
  text-transform: uppercase;
`;

const CenteredLine = styled.hr`
  width: 125px;
  background-color: ${TEXT};
`;

export const SectionLabel = ({ children }: { children: React.ReactNode }) => {
  return (
    <SectionLabelContainer>
      {children}
      <CenteredLine />
    </SectionLabelContainer>
  );
};

export const GrayRow = styled(Row)`
  background-color: ${LIGHT_GRAY};
`;
