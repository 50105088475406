import { Chance } from 'chance';
import { merge } from 'deep-cuts';

export function tenantDataRequest(overrides?: Record<string, string>) {
  const chance = new Chance();
  return merge(
    {
      name: chance.name(),
      email: chance.email(),
      companyName: chance.company(),
      siteAddress: `${chance.address()} ${chance.city()}, ${chance.state()} ${chance.zip()}`,
    },
    overrides || {}
  );
}
