import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import { SectionLabel } from './Common';
import { PRIMARY } from '../../components/common/BlackBearEnergy';

interface ILandingImageProps {
  entity?: Record<string, any>;
  imageLoaded: boolean;
  dataLoaded: boolean;
  onImageLoaded: () => void;
  intro?: Record<string, any>;
}

const LandingImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 50px;

  h2 {
    color: #ffffff;
  }
`;

const Wrapper = styled.div`
  position: relative;
  padding-top: 20%;
  width: 100%;
`;

const SloganContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;
  h2 {
    text-align: center;
    background-color: rgba(105, 105, 105, 0.8);
    padding: 20px;
  }
`;

const ProgramDescriptionContainer = styled.div`
  margin-left: 5%;
  margin-right: 5%;
  background-color: #ffffff;
  padding: 40px;
  z-index: 999999;
`;

const OurEnergyProgramRelative = styled.div`
  position: relative;
`;

const OurEnergyProgramAnchor = styled.div`
  position: absolute;
  top: -80px;
`;

const ProgramDescriptionLeftSideRow = styled(Row)`
  height: 100%;
`;

const VerticallyCenteredCol = styled(Col)`
  display: flex;
  align-items: center;
`;

const ProgramPoint = styled.div`
  h5 {
    color: ${PRIMARY};
  }
`;

const FullWidthImage = styled.img`
  width: calc(100% + 30px);
  margin-left: -15px;
  margin-right: -15px;
`;

const DEFAULT_BACKGROUND_SRC = '../background.jpg';

const useBackgroundImage = (src: string) => {
  const [dataUrl, setDataUrl] = useState<string | false>();
  const [size, setSize] = useState<[number, number]>();

  const load = useCallback(async () => {
    if (!src) return false;

    const response = await fetch(src);
    const blob = await response.blob();
    return new Promise<string>((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onerror = reject;
      fileReader.onload = () => {
        resolve(fileReader.result as string);
      };
      fileReader.readAsDataURL(blob);
    });
  }, [src]);

  useEffect(() => {
    let cancelled = false;
    setDataUrl(undefined);

    load()
      .then((dataUrl) => {
        if (cancelled) return undefined;
        setDataUrl(dataUrl);
      })
      .catch(() => {
        if (cancelled) return undefined;
        setDataUrl(false);
      });

    return () => {
      cancelled = true;
    };
  }, [load]);

  useEffect(() => {
    if (!dataUrl) return setSize(undefined);

    let cancelled = false;
    const image = new Image();

    image.onload = () => {
      if (cancelled) return undefined;
      setSize([image.naturalWidth, image.naturalHeight]);
    };

    image.src = dataUrl;

    return () => {
      cancelled = true;
    };
  }, [dataUrl]);

  return [dataUrl, size] as const;
};

const BackgroundImage = forwardRef<
  HTMLDivElement,
  {
    src?: string | false;
    size?: [width: number, height: number];
    onLoad?: () => void;
  }
>((props, ref) => {
  const { src, size, onLoad } = props;

  useEffect(() => {
    if (src && size) onLoad?.();
  }, [src, size, onLoad]);

  const [targetWidth, setTargetWidth] = useState<number>();
  const [ratio, setRatio] = useState<number>();

  useEffect(() => {
    if (!size || !targetWidth) return undefined;

    const h = setTimeout(() => setRatio(size[0] / targetWidth), 300);

    return () => clearTimeout(h);
  }, [size, targetWidth]);

  useEffect(() => {
    if (!size) return setRatio(undefined);

    const initTargetWidth = window.innerWidth;

    setTargetWidth(initTargetWidth);
    setRatio(size[0] / initTargetWidth);

    const handleResize = () => {
      setTargetWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [size]);

  if (src === false)
    return (
      <FullWidthImage
        key="image"
        src={DEFAULT_BACKGROUND_SRC}
        onLoad={onLoad}
      />
    );

  if (!src || !size || !ratio) return null;

  return <FullWidthImage key="image" src={src} />;
});

const LandingImage = forwardRef<HTMLDivElement, ILandingImageProps>(
  (props: ILandingImageProps, ref) => {
    const { entity, imageLoaded, dataLoaded, onImageLoaded, intro } = props;

    const [dataUrl, size] = useBackgroundImage(entity?.backgroundPhotoUrl);

    const backgroundImage = !!entity && (
      <BackgroundImage
        key="image"
        src={dataUrl}
        size={size}
        onLoad={onImageLoaded}
      />
    );

    return (
      <LandingImageContainer
        ref={ref}
        style={{
          display: imageLoaded && dataLoaded ? 'block' : 'none',
          minHeight: size?.[1] || 600,
          position: 'relative',
        }}
      >
        <div style={{ position: 'absolute', width: '100%', top: 0 }}>
          {backgroundImage}
        </div>
        <Wrapper>
          <SloganContainer>
            <h2>A partner advancing your energy future</h2>
          </SloganContainer>
          <ProgramDescriptionContainer>
            <OurEnergyProgramRelative>
              <OurEnergyProgramAnchor id="our-energy-program" />
            </OurEnergyProgramRelative>
            <Row>
              <Col>
                <SectionLabel>Our Energy Program</SectionLabel>
              </Col>
            </Row>
            <Row>
              <Col xs={6} md={8}>
                <ProgramDescriptionLeftSideRow>
                  <VerticallyCenteredCol xs={12} md={8}>
                    {intro?.text ? (
                      <p>{intro.text}</p>
                    ) : (
                      <p>
                        As part of {entity?.entityName}’s commitment to ESG and
                        net zero goals, we have launched a new program: Solar as
                        an Amenity (SaaA). Under the Solar as an Amenity
                        program, we can install onsite solar and provide you
                        with a unique opportunity to power your facility with
                        solar electricity generated onsite We can offer this
                        regardless of your lease term, and through a simple
                        lease amendment, we will charge you a solar rent adder.
                        Our goal is to lower your total electricity spend and to
                        partner with you to reduce your Scope 2 carbon
                        emissions.
                      </p>
                    )}
                  </VerticallyCenteredCol>
                </ProgramDescriptionLeftSideRow>
              </Col>
              <Col xs={6} md={4}>
                <ProgramPoint>
                  <h5>Cost Effective</h5>
                  <p>Save on your utility bills with no CapEx</p>
                </ProgramPoint>
                <ProgramPoint>
                  <h5>Sustainable</h5>
                  <p>
                    Offset your scope II carbon emissions from your onsite
                    electricity use
                  </p>
                </ProgramPoint>
                <ProgramPoint>
                  <h5>Simple</h5>
                  <p>Just add energy rent into your lease and start saving</p>
                </ProgramPoint>
              </Col>
            </Row>
          </ProgramDescriptionContainer>
        </Wrapper>
      </LandingImageContainer>
    );
  }
);

export default LandingImage;
