import { datadogLogs, StatusType } from '@datadog/browser-logs';

datadogLogs.init({
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN as string,
  site: process.env.REACT_APP_DATADOG_SITE,
  forwardErrorsToLogs: true,
  sampleRate: 100,
});

const logger = datadogLogs.createLogger(
  '@black-bear-energy/tenant-administration-client',
  {
    level: process.env.REACT_APP_LOG_LEVEL as StatusType,
    context: {
      env: process.env.REACT_APP_NODE_ENV,
    },
  }
);

export default logger;
