import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { FormikValues } from 'formik';
import { stringToBoolean } from 'deep-cuts';
import Header from './Header';
import LandingImage from './TenantUtilityForm/LandingImage';
import FAQ, { FAQRow } from './TenantUtilityForm/FAQ';
import Participate from './TenantUtilityForm/Participate';
import logger from '../utils/logger';
import {
  entities as entitiesModule,
  tenantDataRequests as tenantDataRequestsModule,
  faq as faqModule,
  intro as introModule,
} from '../state/store';
import { tenantDataRequest as tenantDataRequestTestData } from '../common/testData';
import { GrayRow } from './TenantUtilityForm/Common';
import ConfirmationModal from './ConfirmationModal';

const { useGetById: useGetEntityById } = entitiesModule;
const { useCreate: useCreateTenantDataRequest } = tenantDataRequestsModule;
const { useGetById: useGetFAQById } = faqModule;
const { useGetById: useGetIntroById } = introModule;

function TenantUtilityForm() {
  const { clientName } = useParams();
  const navigate = useNavigate();
  const {
    method: getEntityById,
    data: entity,
    status: entityStatus,
  } = useGetEntityById();

  const { method: getFAQ, data: faq, status: faqStatus } = useGetFAQById();

  const { method: createTenantDataRequest } = useCreateTenantDataRequest();

  const {
    method: getIntro,
    data: intro,
    status: introStatus,
  } = useGetIntroById();

  const [searchParams] = useSearchParams();
  const [backgroundImageLoaded, setBackgroundImageLoaded] = useState(false);
  const [modal, setModal] = useState(false);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (clientName) {
      getEntityById(clientName);
    }
  }, [clientName]);
  /* eslint-enable react-hooks/exhaustive-deps */

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (entityStatus === 404) {
      logger.info(`tenant ${clientName} not found. `);
      navigate('/not-found');
    }
  }, [entityStatus]);
  /* eslint-enable react-hooks/exhaustive-deps */

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (entity) {
      getIntro(entity.recordId);
      getFAQ(entity.recordId);
    }
  }, [entity]);
  /* eslint-enable react-hooks/exhaustive-deps */

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (faqStatus === 404) getFAQ('default');
  }, [faqStatus]);
  /* eslint-enable react-hooks/exhaustive-deps */

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (introStatus === 404) getIntro('default');
  }, [introStatus]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const initialValues = {
    ...(stringToBoolean(searchParams.get('testData'))
      ? tenantDataRequestTestData()
      : {}),
  } as Record<string, any>;

  const isDataLoaded = () =>
    entityStatus === 200 && faqStatus === 200 && introStatus === 200;

  const onSubmit = async (
    values: FormikValues,
    { setSubmitting }: { setSubmitting: Function }
  ) => {
    try {
      const { status } = await createTenantDataRequest({
        ...values,
        relatedEntity: entity.recordId,
      });

      if (status < 400) {
        setModal(true);
      }
    } catch (e) {
      console.log(e);
    }

    setSubmitting(false);
  };

  const backgroundImage = (
    <LandingImage
      key="background-image"
      entity={entity}
      dataLoaded={isDataLoaded()}
      imageLoaded={backgroundImageLoaded}
      onImageLoaded={() => setBackgroundImageLoaded(true)}
      intro={intro}
    />
  );

  if (clientName && backgroundImageLoaded && isDataLoaded()) {
    return (
      <Container fluid key="root">
        {modal && <ConfirmationModal />}
        <Header clientName={clientName} entity={entity} />
        <GrayRow key="background-image-row">
          <Col key="background-image-col">{backgroundImage}</Col>
        </GrayRow>
        <FAQRow>
          <Col id="faq">
            <FAQ faq={faq} />
          </Col>
        </FAQRow>
        <GrayRow>
          <Col id="participate">
            <Participate initialValues={initialValues} onSubmit={onSubmit} />
          </Col>
        </GrayRow>
      </Container>
    );
  } else {
    return (
      <Container fluid key="root">
        <Row key="background-image-row">
          <Col
            key="background-image-col"
            lg={{ span: 8, offset: 2 }}
            sm={{ span: 10, offset: 1 }}
            xs={12}
            className="text-center"
          >
            <Spinner
              animation="border"
              role="status"
              className="mt-5"
            ></Spinner>
            {backgroundImage}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default TenantUtilityForm;
