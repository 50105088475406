import React from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { SectionLabel } from './Common';
import { LIGHT_GRAY } from '../../components/common/BlackBearEnergy';
import FAQTable from './FAQTable';

interface IFAQProps {
  faq: Record<string, any>[];
}

export const FAQRow = styled(Row)`
  margin-top: 220px;
`;

const FAQContainer = styled.div`
  padding-top: 50px;
  margin-bottom: 30px;
`;

const FAQSection = styled.div`
  background-color: ${LIGHT_GRAY};
  margin: 10px 20px 40px;
  padding: 10px;
  min-height: 130px;

  p {
    font-size: 13px;
  }
`;

function FAQSections({ faq }: IFAQProps) {
  const sections = faq.map((f: any, i: number) => (
    <Row key={i}>
      <Col>
        <FAQSection>
          <h6>{f.question}</h6>
          <p>{f.answer}</p>
        </FAQSection>
      </Col>
    </Row>
  ));

  return (
    <>
      {sections
        .slice(0, -1)
        .concat(<FAQTable key={sections.length + 1} />)
        .concat(sections.slice(-1))}
    </>
  );
}

function FAQ({ faq }: IFAQProps) {
  return (
    <FAQContainer>
      <SectionLabel>FAQ</SectionLabel>
      {faq ? <FAQSections faq={faq} /> : ''}
    </FAQContainer>
  );
}

export default FAQ;
