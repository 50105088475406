import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';

function Success() {
  return (
    <Container fluid>
      <Row>
        <Col lg={{ span: 8, offset: 2 }} sm={{ span: 10, offset: 1 }} xs={12}>
          <Card>
            <Card.Body>
              <p>Your submission was received!</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Success;
